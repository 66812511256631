import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/pagenotfound/_pagenotfound.scss';
import NotFound from './NotFound';

class PageNotFound extends Component {
  render() {
    return (
      <>
        <section className="page-not-found-title h-100">
          <Container fluid className="page-not-found-containter">
            <Row>
              <Col md={12} className="text-center page-not-found-sectionTitle">
                <h1>Page Not Found</h1>
              </Col>
            </Row>
          </Container>
          <NotFound />
        </section>
      </>
    );
  }
}

export default PageNotFound;
