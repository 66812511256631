import { Link } from 'gatsby';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/referringoffice/_referringoffice.scss';

class NotFound extends Component {
  render() {
    return (
      <>
        <section className="page-not-found-content h-100">
          <Container fluid className="referring-office-containter mb-5">
            <Row>
              <Col md={12} className="text-center info-patientTitle mt-3">
                <h3 className="referring-office-servicesSubtitle py-2">
                  404: Page Not Found :(
                </h3>
                <p>
                  You just hit a route that doesn&#39;t exist... the sadness.
                </p>
                <Col md={{ span: 4, offset: 4 }}>
                  <Link to="/" className="page-not-found-button">
                    Home
                  </Link>
                </Col>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default NotFound;
